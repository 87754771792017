<template>
    <Table :data="dataTable.items ? dataTable : getBudgets()" />
</template>

<script>

import { filter } from "@/main.js";
export default {
  components: {
    Table: () => import("@/components/data-table/Table"),
  },
  props: {
    client: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dataTable: {
      title: "Orçamentos",
      new: "budgets-new",
      edit: "budgets-edit",
      // context: "budgets-context",
      filter: "budgets-filter",
      // massAction: "budgets-mass-action",
      headerButtons: {
        filter: false,
        massAction: false,
        new: false
      },
      items: [],
      isLoading: true,
      headers: [
        { text: "N°", value: "number" },
        { text: "Vendedor", value: "seller" },
        { text: "Status", value: "budget_status", align: "center" },
        { text: "Empresa", value: "company" },
        { text: "Cliente", value: "client_name" },
        { text: "Contato", value: "contact" },
        { text: "Data de criação", value: "created_at", align: 'end' },
        { text: "Data de envio", value: "sended_at", align: 'end' },
      ],
    },
  }),
  methods: {
    getBudgets() {
      this.$api
        .post("budget/client",{
          headers:{
                  Authorization:`Bearer ${this.$cookies.get('token')}`
                },
          client_id:this.client.id     
              })
        .then((res) => {
          this.dataTable.items = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dataTable.isLoading = false;
        });
    },
    getFilteredBudgets(obj) {
      obj['client_id'] = this.client.id
      this.$api
        .post("budget/filter", obj)
        .then((res) => {
          this.dataTable.items = res.data.data;
        })
        .catch(() => {
          this.dataTable.items = [];
        })
        .finally(() => {
          this.dataTable.isLoading = false;
        });
    },
  },
  created: function () {
    this.getBudgets();

    filter.$once("action", (obj) => {
      if (obj) this.getFilteredBudgets(obj);
      else this.getBudgets();
    });
  },
  destroyed() {
    this.$store.commit('setStateClient', this.client.id)
  }
};
</script>